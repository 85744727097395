import React, {useEffect, useState} from 'react';
import Preloader from "../../../../../preloader/preloader";
import ApplicationCurrentService from "./application-current-service";
import {applicationServiceApprovePartner} from "../../../../../../store/applications/application-service-order-partner-approve/applicationServiceOrderPartnerApproveSlice";
import {
    applicationServicesGetById,
    currentApplicationServiceById
} from "../../../../../../store/applications/application-service-order/applicationServiceOrderSlice";
import swal from "sweetalert";
import {useDispatch, useSelector} from "react-redux";
import {applicationServiceApproveManager} from "../../../../../../store/applications/application-service-order-manger-approve/applicationServiceOrderMangerApproveSlice";
import ApplicationAddService from "./application-add-service/application-add-service";
import ApplicationCertainServiceItem from "./application-certain-service-item/application-certain-service-item";
import {onValue, ref} from "firebase/database";
import {db} from "../../../../../../firebase";

function AplicationServicesItem(
    {
        // currentServices,
        role,
        approvedPrice,
        setApprovedPrice,
        toggleCompleteApplicationService,
        orderServices,
        setOrderServices,
        handleDeleteService,
        services,
        setKey,
        currentApplicationId,
        orderID,
        showAddServices,
        getApplicationSelectedServices,
        getSelectedServicesForFooterInfo,
        single,
        setShowApproveBtn,
        currentServicesState,
        setCurrentServicesState
    }) {
    const currentServices  = useSelector((state) => state.applicationService);
    const dispatch = useDispatch()
    const [checkedItems, setCheckedItems] = useState({})
    const [error, setError] = useState(null);

    const [pricesEditable, setPricesEditable] = useState(false)
    const [prices, setPrices] = useState({})
    const formatDate = (inputDate) => {
        const dateObject = new Date(inputDate);
        const day = dateObject.getDate().toString().padStart(2, '0');
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObject.getFullYear().toString().slice(2, 4);

        return `${day}.${month}.${year}`;
    };

    const [serviceStatuses, setServiceStatuses] = useState([]);

    const partnerApproveHandle = (id, isChecked) => {
        if (isChecked) {
            // Если чекбокс выбран, добавляем объект в массив
            const updatedServices = [...orderServices.orderServices, {id, status_id: 3}];
            setOrderServices({orderServices: updatedServices});
        } else {
            // Если чекбокс отменен, удаляем объект из массива
            const updatedServices = orderServices.orderServices.filter(service => service.id !== id);
            setOrderServices({orderServices: updatedServices});
        }
    }
    const handleDefaultCheckedState = (serviceId, serviceCompleted) => {
        return serviceCompleted === 1;
    };
    const checkStatus = (serviceId, serviceCompleted) => {
        toggleCompleteApplicationService(serviceId);
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [serviceId]: !prevCheckedItems[serviceId],
        }));
    };
    //update order from firebase
    // useEffect(() => {
    //     const ordersRef = ref(db, `myparkingdev/orders-in-applications/${single.application?.id}`);
    //     const unsubscribe = onValue(ordersRef, (snapshot) => {
    //
    //         if (!snapshot.val()){
    //             return
    //         }
    //         else {
    //             console.log(snapshot.val().orderServices)
    //             const newOrder = snapshot.val().orderServices
    //             setCurrentServicesState(newOrder)
    //             setError(null);
    //         }
    //     }, (error) => {
    //         setError(error.message);
    //     });
    //     return () => {
    //         unsubscribe();
    //     };
    // }, []);

    // Set default checked state when component mounts
    useEffect(() => {
        const defaultCheckedState = {};
        currentServicesState?.map((service) => {
            defaultCheckedState[service.id] = handleDefaultCheckedState(service.id, service.completed);
        });
        setCheckedItems(defaultCheckedState);
    }, [currentServicesState]);
    useEffect(() => {
        if (services.currentService && services.currentService.orderServices) {
            const extractedPrices = services.currentService.orderServices.map(service => ({
                id: service.id,
                price_for_us: service.price_for_us,
                approved_price: service.approved_price,
            }));
            setPrices(extractedPrices);
        }
    }, [services.currentService.orderServices])
    const handleInputChange = (e, id) => {
        const {value, name} = e.target
        setPrices(prevPrices =>
            prevPrices.map(price =>
                price.id === id ? {...price, [name]: value} : price
            )
        );

    }
    useEffect(()=>{
        dispatch(currentApplicationServiceById({currentApplicationId, orderID})).then((response)=>{
            setCurrentServicesState(response.payload.orderServices)
        })
    },[])
    useEffect(() => {
        if (serviceStatuses.some(status => status.statusId === 1)) {
            setShowApproveBtn(1); // If any status === 1
        } else if (serviceStatuses.some(status => status.statusId === 2)) {
            setShowApproveBtn(2); // If any status === 2, but none are 1
        } else if (serviceStatuses.every(status => status.statusId === 3)) {
            setShowApproveBtn(3); // If all statuses === 3
        }
    }, [serviceStatuses]);

    // Function to update statuses in the parent component
    const updateServiceStatus = (serviceId, statusId) => {
        setServiceStatuses(prevStatuses => {
            const updatedStatuses = prevStatuses.map(status =>
                status.serviceId === serviceId ? { ...status, statusId } : status
            );
            // If the service doesn't exist, add it
            if (!updatedStatuses.find(status => status.serviceId === serviceId)) {
                updatedStatuses.push({ serviceId, statusId });
            }
            return updatedStatuses;
        });
    };
    switch (role) {
        case 'Partner' :
        case 'Operator' :
            return currentServices && (
                <div className="tab-pane fade show" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                    {!showAddServices ?
                        <table className="price-list-table table-orders">
                            <thead

                                style={{width: '100%', borderBottom: '1px solid #e7e7e7'}}
                            >
                            <tr>
                                <th style={{paddingLeft: 0}}></th>
                                <th>Наименование</th>
                                <th className={'text-center'}>Цена</th>
                                <th></th>
                            </tr>

                            </thead>
                            <tbody className={'modal-services__list'}>
                            {currentServicesState?.map((service, index) => {
                                return (
                                    <tr>
                                        <td
                                            onClick={() => {
                                                handleDeleteService(service.id)
                                            }}
                                            className={'delete_service'}>
                                            {service.status !== 'Удалена' && service.status !== 'Согласована' ?
                                            <span className="">
                                                    <svg width="10" height="11" viewBox="0 0 10 11" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M0.52827 1.02729C0.788619 0.766944 1.21073 0.766944 1.47108 1.02729L4.99967 4.55589L8.52827 1.02729C8.78862 0.766944 9.21073 0.766944 9.47108 1.02729C9.73143 1.28764 9.73143 1.70975 9.47108 1.9701L5.94248 5.4987L9.47108 9.02729C9.73143 9.28764 9.73143 9.70975 9.47108 9.9701C9.21073 10.2305 8.78862 10.2305 8.52827 9.9701L4.99967 6.44151L1.47108 9.9701C1.21073 10.2305 0.788619 10.2305 0.52827 9.9701C0.26792 9.70975 0.26792 9.28764 0.52827 9.02729L4.05687 5.4987L0.52827 1.9701C0.26792 1.70975 0.26792 1.28764 0.52827 1.02729Z"
                                                            fill="#EB5757"/>
                                                    </svg>
                                                </span> : ''
                                            }

                                        </td>
                                        <td>
                                            <div className="table-service-title"
                                                 style={service.status === 'Удалена' ? {color: 'red'} : {color: '#000'}}
                                            >{service.parking_has_service !== null ? service.parking_has_service.parking_service.name : service.custom_service}</div>
                                        </td>

                                        <td className={`text-center price_service ${service.approved_price === 0 ? 'text-right' : ''}`}>
                                            {service.approved_price === 0 ?
                                                <>
                                                    <span className={'tip-service'}>?</span>
                                                    <span className={'tip-content'}>
                                                        {service.parking_has_service !== null ? service.parking_has_service.comment : ''}
                                                    </span>
                                                </>
                                                : ''
                                            }
                                            {service.status !== 'Удалена' && service.approved_price}
                                        </td>
                                        <td className="text-right">
                                            {service.status === 'На согласовании' ?
                                                <span>
                                                    {currentServices?.currentServices?.currentService?.order?.status_code === 'toManager' ?
                                                        'Ожидает согласования'
                                                        : 'Согласовать'
                                                    }
                                                </span>
                                                :
                                                <span
                                                    style={service.status === 'Удалена' ? {color: 'red'} : service.status === 'Выполнена' ? {color: 'green'} : {color: '#000'}}
                                                > {service.status} </span>
                                            }

                                        </td>
                                        {service.status === 'На согласовании' && currentServices?.currentService?.order?.status_code !== 'toManager' ?
                                            <td className="text-right d-flex justify-content-end align-items-center">
                                                <label className="switch-radio-wrap d-flex flex-row-reverse">
                                                    <input
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            partnerApproveHandle(service.id, e.target.checked)
                                                        }}
                                                    />
                                                    <span className="switcher-radio ml-2 mr-0"></span>
                                                </label>
                                            </td> : <td></td>}

                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        : <ApplicationAddService
                            getApplicationSelectedServices={getApplicationSelectedServices}
                            getSelectedServicesForFooterInfo={getSelectedServicesForFooterInfo}
                            single={single}
                        />
                    }
                </div>
            );
            break;
        case 'Manager' :
        case  'SuperAdmin' :
        case 'Admin' :
            if (currentServices?.currentService?.order?.status_code === 'toManager') {
                return currentServices && (
                    <div className="tab-pane fade show" id="nav-profile" role="tabpanel"
                         aria-labelledby="nav-profile-tab">
                        {!showAddServices ?
                            <table className="price-list-table table-orders">
                                <thead
                                    style={{width: '100%', borderBottom: '1px solid #e7e7e7'}}
                                >
                                <tr>
                                    <th style={{paddingLeft: 0}}></th>
                                    <th>Наименование</th>
                                    <th>Цена для заказчика</th>
                                    <th>Наша цена</th>
                                    <th></th>
                                </tr>

                                </thead>
                                <tbody className={'modal-services__list'}>
                                {currentServicesState ? currentServicesState.map((service, index) => {
                                    return (
                                        <ApplicationCurrentService
                                            key={orderID+index}
                                            service={service}
                                            setApprovedPrice={setApprovedPrice}
                                            handleDeleteService={handleDeleteService}
                                            currentApplicationId={currentApplicationId}
                                            orderID={orderID}
                                            updateServiceStatus={updateServiceStatus}
                                            orderStatus={currentServices?.currentService?.order?.status_code}
                                        />
                                    )
                                }) : <Preloader/>}
                                </tbody>
                            </table>
                            : <ApplicationAddService
                                getApplicationSelectedServices={getApplicationSelectedServices}
                                getSelectedServicesForFooterInfo={getSelectedServicesForFooterInfo}
                                single={single}
                            />
                        }
                    </div>
                );
            }
            if (currentServices?.currentService?.order?.status_code === 'toPartner') {
                return currentServices && (
                    <div className="tab-pane fade show" id="nav-profile" role="tabpanel"
                         aria-labelledby="nav-profile-tab">
                        {!showAddServices ?
                            <table className="price-list-table table-orders">
                                <thead
                                    style={{width: '100%', borderBottom: '1px solid #e7e7e7'}}
                                >
                                <tr>
                                    <th style={{paddingLeft: 0}}></th>
                                    <th>Наименование</th>
                                    <th>Цена для заказчика</th>
                                    <th>Наша цена</th>
                                    <th></th>
                                </tr>

                                </thead>
                                <tbody className={'modal-services__list'}>
                                {currentServicesState?.map((service, index) => {
                                    return (
                                        <tr>

                                            <td
                                                onClick={() => {
                                                    handleDeleteService(service.id)
                                                }}
                                                className={'delete_service'}
                                            >
                                                {service.status !== 'Удалена' && service.status !== 'Согласована' ?
                                                    <span className="">
                                                <svg width="10" height="11" viewBox="0 0 10 11" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M0.52827 1.02729C0.788619 0.766944 1.21073 0.766944 1.47108 1.02729L4.99967 4.55589L8.52827 1.02729C8.78862 0.766944 9.21073 0.766944 9.47108 1.02729C9.73143 1.28764 9.73143 1.70975 9.47108 1.9701L5.94248 5.4987L9.47108 9.02729C9.73143 9.28764 9.73143 9.70975 9.47108 9.9701C9.21073 10.2305 8.78862 10.2305 8.52827 9.9701L4.99967 6.44151L1.47108 9.9701C1.21073 10.2305 0.788619 10.2305 0.52827 9.9701C0.26792 9.70975 0.26792 9.28764 0.52827 9.02729L4.05687 5.4987L0.52827 1.9701C0.26792 1.70975 0.26792 1.28764 0.52827 1.02729Z"
                                                        fill="#EB5757"/>
                                                </svg>
                                            </span>
                                                    : ''
                                                }
                                            </td>
                                            <td>
                                                <div
                                                    className="table-service-title"
                                                    style={service.status === 'Удалена' ?
                                                        {color: 'red'} : {color: '#000'}}
                                                >{service.parking_has_service !== null ? service.parking_has_service.parking_service.name : service.custom_service}</div>
                                            </td>

                                            <td className="text-center">
                                                {service.status !== 'Удалена' ? service.approved_price : ''}
                                            </td>
                                            <td className="text-center">
                                                {service.status !== 'Удалена' ? service.price_for_us : ''}
                                            </td>
                                            <td className="text-right">
                                                <label className="switch-radio-wrap d-flex flex-row-reverse">
                                                <span
                                                    style={service.status === 'Удалена' ?
                                                        {color: 'red'} : service.status === 'На согласовании' ? {color: '#000'} : {color: 'green'}}
                                                    className="condition">
                                                    {
                                                        service.status === 'На согласовании' ?
                                                            'Ожидаем согласования' : service.status
                                                    }
                                                </span>
                                                </label>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                            : <ApplicationAddService
                                getApplicationSelectedServices={getApplicationSelectedServices}
                                getSelectedServicesForFooterInfo={getSelectedServicesForFooterInfo}
                                single={single}
                            />
                        }
                    </div>
                );
            }
            if (currentServices?.currentService?.order?.status_code === 'approved' ||
                currentServices?.currentService?.order?.status_code === 'in-progress' ||
                currentServices?.currentService?.order?.status_code === 'partly-completed') {
                return currentServices && (
                    <div className="tab-pane fade show" id="nav-profile" role="tabpanel"
                         aria-labelledby="nav-profile-tab">
                        {!showAddServices ?
                            <table className="price-list-table table-orders">
                                <thead
                                    style={{width: '100%'}}
                                >
                                <tr>
                                    <th style={{paddingLeft: 0}}></th>
                                    <th>Наименование</th>
                                    <th>Цена для заказчика</th>
                                    <th>Наша цена</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody className={'modal-services__list'}>
                                <tr>
                                    <td style={{paddingLef:'0'}}></td>
                                    <td></td>
                                    <td></td>
                                    <td className={'d-flex justify-content-end'}>

                                    </td>

                                </tr>
                                {currentServicesState?.map((service, index) => {
                                    const isChecked = checkedItems[service.id];
                                    return (
                                        <>

                                            <ApplicationCertainServiceItem
                                                handleDeleteService={handleDeleteService}
                                                service={service}
                                                handleInputChange={handleInputChange}
                                                isChecked={isChecked}
                                                checkStatus={checkStatus}
                                                services={services}
                                                prices={prices}
                                                setCurrentServicesState={setCurrentServicesState}
                                            />
                                        </>
                                    )
                                })}
                                </tbody>
                            </table>
                            : <ApplicationAddService
                                getApplicationSelectedServices={getApplicationSelectedServices}
                                getSelectedServicesForFooterInfo={getSelectedServicesForFooterInfo}
                                single={single}
                            />
                        }
                    </div>
                );
            }
            if (currentServices?.currentService?.order?.status_code === 'cancelled' || currentServices?.currentService?.order?.status_code === 'completed') {
                return (
                    <div className="tab-pane fade show" id="nav-profile" role="tabpanel"
                         aria-labelledby="nav-profile-tab">
                        {!showAddServices ?
                            <table className="price-list-table table-orders">
                                <thead
                                    style={{width: '100%'}}
                                >
                                <tr>
                                    <th>Наименование</th>
                                    <th className={'text-center'}>Цена для заказчика</th>
                                    <th className={'text-center'}>Наша цена</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {currentServicesState?.map((service, index) => {
                                    return (
                                        <tr>
                                            <td>
                                                <div
                                                    className="table-service-title">{service.parking_has_service !== null ? service.parking_has_service.parking_service.name : service.custom_service}</div>
                                            </td>

                                            <td className="text-center">
                                                {service.approved_price}
                                            </td>
                                            <td className="text-center">
                                                {service.price_for_us}
                                            </td>
                                            <td className="text-right">
                                                {/*{service.status === 'На согласовании' ?*/}
                                                {/*    <span>*/}
                                                {/*    {currentServices?.currentService?.order?.status_code === 'toManager' ?*/}
                                                {/*        'Ожидает согласования'*/}
                                                {/*        : 'Согласовать'*/}
                                                {/*    }*/}
                                                {/*</span>*/}
                                                {/*    :*/}
                                                {/*    */}
                                                {/*}*/}
                                                <span
                                                    style={{color: 'green'}}
                                                > {currentServices?.currentService?.order?.status_code === 'completed' ? 'выполнено' : 'удалено'} </span>

                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                            : <ApplicationAddService
                                getApplicationSelectedServices={getApplicationSelectedServices}
                                getSelectedServicesForFooterInfo={getSelectedServicesForFooterInfo}
                                single={single}
                            />
                        }
                    </div>
                )
            }
        default :
            return !currentServices && (
                <Preloader/>
            );
    }
}

export default AplicationServicesItem;