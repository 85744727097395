import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

function ApplicationAddExistingService(
    {
        single,
        collectDataAboutSelectedServices,
        groupedData,
        selectedServices,
        filterId,
        setFilterId,
        categories
    }) {

    const {user} = useSelector((state) => state.auth);
    const {orderServices} = useSelector((state) => state.applicationService.currentService);

    return (
        <>
            {single.parkingHasServices && single.parkingHasServices.length !== 0 ?
                <div className="tab-content" id="v-pills-tabContent">
                    <div className="tab-pane fade show active mlr-20 table-data" id="v-pills-tab10"
                         role="taborderspanel" aria-labelledby="v-pills-tab10-tab">
                        <div className="filers__by-categories">
                            {categories && categories.map((category, index) => {
                                return (
                                    <span
                                        key={category.catId}
                                        className={category.catId === filterId ? 'active' : ''}
                                        onClick={() => {
                                            setFilterId(category.catId)
                                        }}
                                    >
                                    {category.catName}
                                </span>
                                )
                            })}
                        </div>
                        <table className="price-list-table table-services">
                            <thead style={{borderBottom: '1px solid #ededed'}}>
                            <th>
                                <span>Наименование</span>
                            </th>
                            <th className={user.role === 'Partner' ? 'text-center' : ''}>
                                <span> {user.role !== 'Partner' ? 'Цена для заказчика' : 'Цена'} </span>
                            </th>
                            {user.role !== 'Partner' &&
                            <th>
                                <span>Наша цена</span>
                            </th>
                            }
                            <th></th>
                            </thead>
                            <tbody>
                            {groupedData && groupedData.length !== 0 && groupedData.map(item => {
                                const isInOrder = orderServices.
                                some(order => order.parking_has_service !== null ? order.parking_has_service.id === item.id : order.id === item.id);
                                return (
                                    <tr
                                        key={item.id}
                                        className={filterId === item.parking_service.service_category.id ? '' : 'd-none'}
                                    >
                                        <td>
                                            <div className="table-service-title" style={isInOrder ? {color : '#9C9C9C'} : {color: '#000'}} >
                                                {item.parking_service !== null ? item.parking_service.name : item.custom_service}</div>
                                        </td>
                                        <td className={`text-center  ${user.role !== 'Partner' ? 'd-flex justify-content-between' : ''}`}>
                                      <span>
                                        {item.cost && !isInOrder ? `${item.cost}` : ''}
                                      </span>
                                        </td>
                                        {user.role !== 'Partner' && (
                                            <td>
                                        <span>
                                          {item.cost_for_us && !isInOrder ? `${item.cost_for_us}` : ''}
                                        </span>
                                            </td>
                                        )}
                                        <td className="text-right d-flex justify-content-end">
                                            {/* Conditionally render based on if the service is already in the order */}
                                            {isInOrder ? (
                                                <span className="condition text-red" style={{color:'#9C9C9C'}}>Уже в заказе</span>
                                            ) : (
                                                <label className="switch-radio-wrap d-flex flex-row-reverse">
                                                    <input
                                                        type="checkbox"
                                                        onClick={(e) => {
                                                            collectDataAboutSelectedServices(
                                                                e,
                                                                item.parking_id,
                                                                item.id,
                                                                item.cost,
                                                                item.cost_for_us,
                                                                item.parking_service !== null ? item.parking_service.name : item.custom_service
                                                            );
                                                        }}
                                                        checked={selectedServices.find((s) => s.parking_has_service_id === item.id)}
                                                    />
                                                    <span className="switcher-radio ml-2 mr-0"></span>


                                                    {selectedServices.find((s) => s.parking_has_service_id === item.id) ? (
                                                        <span className="condition text-green"
                                                              style={{color: '#27AE60'}}>Добавлено </span>
                                                    ) : (
                                                        <span className="condition"
                                                              style={{minWidth: '100px'}}>Добавить</span>
                                                    )}

                                                </label>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>

                        </table>
                    </div>
                </div> : <div>
                    <span>У данной стоянки нет активных услуг</span>
                </div>
            }

        </>
    );
}

export default ApplicationAddExistingService;